import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { URLs } from "constants/urls";
import Modal from "components/UI/Modal";
import { toastNotification } from "common/util_function";
import EventTypesList from "components/EventTypes/List";
import EventTypesForm from "components/EventTypes/Form";
import { userService } from '../../services/user.service';
import EventTypesFilter from "components/EventTypes/Filter";

const defaultData = {
  name: "",
  categoryTypeId: ""
};

const EventType = () => {
  const [formData, setFormData] = useState(defaultData);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [results, setResults] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    list();
    subCategories();
    return () => { }
  }, [])

  const list = async (data) => {
    const config = {
      method: "post",
      url: URLs.eventTypes.list,
      data
    };
    setIsLoading(true);
    try {
      const response = await axios(config);
      if (response.data.code === 200) {
        setResults(response.data.data)
        setIsLoading(false)
      } else {
        setResults([]);
        setIsLoading(false)
        throw new Error(response.data.message);
      }
    } catch (error) {
      setIsLoading(false)
      toastNotification("error", error.message);
    }
  }

  const openForm = useCallback((item) => {
    document.body.classList.add("overflow-hidden");
    window.scrollTo(0, 0);
    const data = {
      id: item.id,
      name: item.name,
      categoryTypeId: item.categoryTypeId
    }
    setFormData(data);
    setIsOpenForm(true);
  });

  const onDeleteHandler = (item) => {
    const url = `${URLs.eventTypes.delete}?id=${item.id}&status=${item.active}`;
    axios
      .get(url)
      .then((response) => {
        if (response.data.code === 200) {
          toastNotification("success", response.data.message);
          list();
        } else {
          toastNotification("error", response.data.message);
          throw new Error(response.data.message);
        }
      })
      .catch((err) => {
        toastNotification("error", "Failed to delete the event!, Please try again.");
      });
  };

  const subCategories = () => {
    const url = URLs.category.types;
    axios.get(url)
      .then((response) => {
        if (response.data.code === 200) {
          const results = response.data.data;
          setSubCategoryList(results);
        } else {
          setSubCategoryList([]);
        }
      })
      .catch((err) => {
        setSubCategoryList([]);
      });
  }

  const onSubmit = async (fields) => {
    const config = {
      method: "post",
      url: URLs.eventTypes.form,
      data: { ...fields, userId: userService.userId() },
    };

    axios(config)
      .then((response) => {
        if (response.data.code === 200) {
          toastNotification("success", response.data.message);
          list();
          setTimeout(() => {
            onClose();
          }, 1000);
        } else {
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        toastNotification("error", error);
      });
  }

  const onClose = () => {
    document.body.classList.remove("overflow-hidden");
    setIsOpenForm(false);
    window.scrollTo(0, 0);
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-full lg:w-12/12 mt-20 px-4">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-gray-800 text-xl font-bold uppercase">Category List</h6>
              <button type="submit" onClick={() => openForm(defaultData)} className="bg-blue-800 text-white focus:outline-none font-bold py-2 px-4 rounded">Add New </button>
            </div>
          </div>
          <div className="w-full">
            <EventTypesFilter categoryTypeList={subCategoryList} onFilter={list} />
            <EventTypesList
              results={results}
              onEdit={openForm}
              onDelete={onDeleteHandler}
              isLoading={isLoading}
            />
          </div>
        </div>
        {isOpenForm && (
          <Modal onClose={() => { }} className="cb-large">
            <div className="flex justify-between items-center p-4 bg-blue-600">
              <h6 className="text-white text-xl font-bold uppercase">Event Form</h6>
              <button onClick={onClose} className="cursor-pointer border-2 h-6 w-6 flex items-center focus:outline-none justify-center rounded-xl"><i className="fas fa-times text-white"></i></button>
            </div>
            <div className="px-8 py-4">
              <EventTypesForm onSubmit={onSubmit} subCategoryList={subCategoryList} formData={formData} />
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
}

export default EventType;