import React from "react";
import { Formik, Field, Form } from 'formik';

const EventTypesFilter = ({ categoryTypeList, onFilter }) => {
  const defaultData = {
    categoryTypeId: ''
  }

  const getDropdownOptions = (list, defaultName) => {
    const defaultOption = <option key="default" value="Select">{defaultName}</option>
    const options = list.map((item, index) => {
      return (
        <option key={index} value={item.id}>
          {item.name.trim()}
        </option>
      );
    });
    return [defaultOption, ...options];
  };

  return (
    <div className="p-4">
      <div className="my-4 p-4 border-b-2 bg-white rounded">
        <Formik initialValues={defaultData} onSubmit={onFilter}>
          {({ setFieldValue, isSubmitting, handleReset }) => (
            <Form noValidate className="flex flex-wrap items-end justify-between">
              <div className="mr-2 mb-2">
                <label className="block text-xs mb-2 uppercase font-bold text-left">Category Filter</label>
                <Field
                  as="select"
                  name="categoryTypeId"
                  className="px-3 py-3 placeholder-gray-400 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 text-gray-700 bg-white"
                >
                  {getDropdownOptions(categoryTypeList, "--Select Category--")}
                </Field>
              </div>

              <div className="flex flex-row mb-2">
                <button type="reset" className="w-full bg-gray-300 text-gray-100 focus:outline-none font-bold py-2 px-2 rounded mr-2" onClick={handleReset}>Clear</button>
                <button type="submit" disabled={isSubmitting} className="w-full bg-blue-800 text-white focus:outline-none font-bold py-2 px-2 rounded">Search</button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EventTypesFilter;