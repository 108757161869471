const set_user_data = (data) => {
  return {
    type: "SET_USER_DATA",
    data: data,
  };
};

const set_user_id = (data) => {
  return {
    type: "SET_USER_ID",
    data: data,
  };
};

const set_is_authenticated = (data) => {
  return {
    type: "SET_IS_AUTHENTICATED",
    data: data,
  };
};

export default { set_user_data, set_is_authenticated, set_user_id };
