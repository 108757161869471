import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import DateFormat from "../UI/DateFormat";
import Status from "../UI/Status";
import ProjectLogo from "../UI/ProjectLogo";
import Modal from "../UI/Modal";
import Pagination from "../UI/Pagination";
import Loading from "components/UI/Loading";
import ConfirmModal from "components/UI/ConfirmModal";

const FeaturedList = ({ paginationConfig, onEdit, onFetchRecords, results, onDelete, isLoading }) => {
  const [selectedItem, setSelectedItem] = useState("");
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onRemoveHandler = (item) => {
    setSelectedItem(item);
    setDeleteStatus(item.active);
    document.body.classList.add("overflow-hidden");
    window.scrollTo(0, 0);
    setShowConfirmModal(true);
  };

  const onEditHandler = (item) => {
    onEdit(item);
  };

  const onClose = () => {
    document.body.classList.remove("overflow-hidden");
    setShowConfirmModal(false);
  };

  const onNavigationHandler = (limit, page) => {
    onFetchRecords(limit, page);
  };

  const confirmHandler = (action) => {
    document.body.classList.remove("overflow-hidden");
    setShowConfirmModal(false);
    if (action) {
      onDelete(selectedItem);
    }
  };

  return (
    <div className="p-4">
      <Pagination
        className="mb-3"
        paginationConfig={paginationConfig}
        onNavigation={onNavigationHandler}
      />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th width="100px">Id</th>
            <th>Project</th>
            <th>Category</th>
            <th>Updated On</th>
            <th>Updated By</th>
            <th>Active</th>
            <th width="115px">Action</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? <tr key="loading"><td colSpan={7}><Loading></Loading></td></tr> :
            results.map((item) => {
              return (
                <tr key={item.id}>
                  <td>#P{item.id}</td>
                  <td>
                    <ProjectLogo logo={item.project} />
                  </td>
                  <td>{+item.isLaunchpad === 1 ? "Platform" : "Project"}</td>
                  <td><DateFormat date={Date.parse(item.modifiedAt)} /></td>
                  <td>{item.username}</td>
                  <td><Status value={item.active} /></td>
                  <td>
                    <div className="btn-container">
                      <Button
                        className="mr-2"
                        variant="secondary"
                        onClick={() => onRemoveHandler(item)}
                      >
                        <i className="fas fa-trash text-gray-400"></i>
                      </Button>
                      <Button
                        className="float-right"
                        variant="secondary"
                        onClick={() => onEditHandler(item)}
                      >
                        <i className="fas fa-edit text-gray-400"></i>
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </Table>
      {showConfirmModal && (
        <Modal onClose={onClose} className="cb-small">
          <ConfirmModal
            description={`Are you sure want to ${+deleteStatus ? "de-activate" : "activate"
              }?`}
            onConfirmModal={confirmHandler}
          />
        </Modal>
      )}
      <Pagination
        paginationConfig={paginationConfig}
        onNavigation={onNavigationHandler}
      />
    </div>
  );
};


export default FeaturedList;