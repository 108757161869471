import React from "react";
import { useHistory } from "react-router";
import { userService } from '../../services/user.service';

const Navbar = () => {
  const history = useHistory();

  const logout = () => {
    userService.logout();
    history.push("/login");
  }
  return (
    <>
      <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-no-wrap md:justify-start flex items-center p-4">
        <div className="w-full mx-autp items-center flex justify-between md:flex-no-wrap flex-wrap md:px-10 px-4">
          <a
            className="text-white text-sm uppercase hidden lg:inline-block font-semibold"
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            Dashboard
          </a>
          <button
            className="text-white text-sm uppercase hidden lg:inline-block font-semibold outline-none focus:outline-none"
            type="button"
            onClick={logout}
          >
            Logout
          </button>
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}

export default Navbar;
