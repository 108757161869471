import React, {useEffect, useState} from "react";

const DateFormat = (props) => {
  const [date, setDate] = useState("");
  useEffect(() => {
    const cDate = new Date(props.date);
    const customDate = `${cDate
      .getDate()
      .toString()
      .padStart(2, "0")}-${cDate.toLocaleString("default", {
      month: "short",
    })}-${cDate.getFullYear()}`;
    setDate(customDate);
  }, [props.date]);

  return <div className={props.className}>{date}</div>;
};

export default DateFormat;
