import React, { useState, useEffect, Fragment } from "react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import "../../assets/styles/scrollbar.css";

const cliffList = [
  {
    id: "days",
    name: "Days"
  },
  {
    id: "weeks",
    name: "Weeks"
  },
  {
    id: "months",
    name: "Months"
  },
  {
    id: "years",
    name: "Years"
  }
]

const vestingList = [
  {
    id: "months",
    name: "Months"
  },
  {
    id: "years",
    name: "Years"
  }
]

const vestingIntervalList = [
  {
    id: "daily",
    name: "Daily"
  },
  {
    id: "weekly",
    name: "Weekly"
  },
  {
    id: "monthly",
    name: "Monthly"
  },
  {
    id: "yearly",
    name: "Yearly"
  }
]

const AdvancedStep = ({ formFields, values, validateTGE, setFieldValue, config }) => {
  const { salesRounds } = config;
  const [showAddButton, setShowAddButton] = useState(true);
  const [formData, setFormData] = useState(formFields);

  useEffect(() => {
    const sum = formData.tableData.reduce((a, b) => a + (b.tokenTGE ? +b.tokenTGE : 0), 0);
    setShowAddButton(sum >= 100 ? false : true);
    validateTGE(sum === 100 || sum === 0 ? true : false);
  }, [formData]);

  const getDropdownOptions = (list, defaultName) => {
    const defaultOption = <option key="default" value="Select">{defaultName}</option>
    const options = list.map((item, index) => {
      return (
        <option key={index} value={item.id}>
          {item.name}
        </option>
      );
    });
    return [defaultOption, ...options];
  };

  // handle input change
  const handleInput = (e, name) => {
    setFieldValue(name, e.target.value);
    const tempData = { ...formData }
    tempData[name] = e.target.value
    setFormData(tempData)
  }
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...formData.tableData];
    list[index][name] = value;
    if (name === "tokenTGE") {
      list[index]["tokenFundraised"] = (formData.totalSupply * (value / 100)).toFixed(0);
    }
    const sum = list.reduce((a, b) => a + (b.tokenTGE ? +b.tokenTGE : 0), 0);
    setFieldValue("tableData", list);
    setFormData({ ...formData, tableData: list });
    setShowAddButton(sum >= 100 ? false : true);
    validateTGE(sum === 100 || sum === 0 ? true : false);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...formData.tableData];
    list.splice(index, 1);
    setFormData({ ...formData, tableData: list });
  };

  // handle click event of the Add button
  const handleAddClick = (index) => {
    const currentRow = formData.tableData[index];
    if (currentRow.tokenTGE) {
      setFormData({
        ...formData,
        tableData: [
          ...formData.tableData,
          {
            round: "",
            initialPrice: "",
            tokenTGE: "",
            tokenFundraised: "",
            cliffCategory: "",
            cliff: "",
            vestingCategory: "",
            vesting: "",
            vestingInterval: ""
          },
        ],
      });
    }
  };

  return (
    <div className="p-4">
      <Fragment>
        <div className="flex flex-wrap items-center justify-between px-4 py-2">
          <div className="w-1/2">
            <label className="block text-xs mb-2 uppercase font-bold">Token Price</label>
            <div className="w-full pr-4">
              <Field type="text" name="tokenPrice" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Token Price" autoComplete="off" />
            </div>
          </div>

          <div className="w-1/2">
            <label className="block text-sm mb-2 font-bold">Initial Market Cap</label>
            <div className="w-full">
              <Field type="text" name="initialMarketCap" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Initial Market Cap" autoComplete="off" />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-between px-4 py-2 mb-4">
          <div className="w-1/2">
            <label className="block text-xs mb-2 uppercase font-bold">Initial Token Supply</label>
            <div className="w-full pr-4">
              <Field type="text" name="initialTokenSupply" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Initial Token Supply" autoComplete="off" onChange={(e) => handleInput(e, "initialTokenSupply")} />
            </div>
          </div>
          <div className="w-1/2">
            <label className="block text-xs mb-2 uppercase font-bold">Total Supply</label>
            <div className="w-ful">
              <Field type="text" name="totalSupply" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Total Supply" autoComplete="off" onChange={(e) => handleInput(e, "totalSupply")} />
            </div>
          </div>
        </div>
      </Fragment>
      <span className="text-gray-800 text-xl font-bold p-4 mb-4">Sales round details</span>
      <div className="w-full bg-white custom-scrollbar">
        <div className="p-4">
          <table>
            <thead>
              <tr className="text-left">
                <th>Round Category</th>
                <th>Token Price</th>
                <th>TGE(%)</th>
                <th>Token Distribution</th>
                <th>Cliff</th>
                <th>Vesting</th>
                <th>Vesting Interval</th>
                <th width="115px">Action</th>
              </tr>
            </thead>
            <tbody>
              {formData.tableData &&
                formData.tableData.map((row, i) => {
                  return (
                    <tr key={`${row.roi}-${i}`}>
                      <td>
                        <Field
                          as="select"
                          name="round"
                          value={row.round}
                          onChange={(e) => handleInputChange(e, i)}
                          className="p-2 border-1 cursor-pointer outline-none focus:bg-gray-300"
                        >
                          {getDropdownOptions(salesRounds, "--Select Round--")}
                        </Field>
                      </td>
                      <td>
                        <Field type="text"
                          name="initialPrice"
                          placeholder="Initial Price"
                          value={row.initialPrice}
                          onChange={(e) => { handleInputChange(e, i); }}
                          className="p-2 border-1 max-w-100-px cursor-pointer outline-none focus:bg-gray-300" />
                      </td>
                      <td>
                        <Field type="text"
                          name="tokenTGE"
                          placeholder="Percentage"
                          value={row.tokenTGE}
                          onChange={(e) => handleInputChange(e, i)}
                          className="p-2 border-1 max-w-100-px cursor-pointer outline-none focus:bg-gray-300"
                        />
                      </td>
                      <td>
                        <Field type="text"
                          name="tokenFundraised"
                          placeholder="Fundraised"
                          disabled={true}
                          value={row.tokenFundraised}
                          onChange={(e) => handleInputChange(e, i)}
                          className="p-2 border-1 cursor-pointer outline-none focus:bg-gray-300" />
                      </td>
                      <td>
                        <div className="flex items-center justify-betwee">
                          <Field
                            as="select"
                            name="cliffCategory"
                            onChange={(e) => handleInputChange(e, i)}
                            value={row.cliffCategory}
                            className="p-2 border-1 cursor-pointer outline-none focus:bg-gray-300"
                          >
                            {getDropdownOptions(cliffList, "--Select Cliff--")}
                          </Field>
                          <Field type="text"
                            name="cliff"
                            placeholder="Cliff Value"
                            value={row.cliff}
                            onChange={(e) => handleInputChange(e, i)}
                            className="ml-1 p-2 border-1 max-w-100-px cursor-pointer outline-none focus:bg-gray-300" />
                        </div>
                      </td>
                      <td>
                        <div className="flex items-center justify-betwee">
                          <Field
                            as="select"
                            name="vestingCategory"
                            onChange={(e) => handleInputChange(e, i)}
                            value={row.vestingCategory}
                            className="p-2 border-1 cursor-pointer outline-none focus:bg-gray-300"
                          >
                            {getDropdownOptions(vestingList, "--Select Vesting--")}
                          </Field>
                          <Field type="text"
                            name="vesting"
                            placeholder="Vesting Value"
                            value={row.vesting}
                            onChange={(e) => handleInputChange(e, i)}
                            className="ml-1 p-2 border-1 max-w-100-px cursor-pointer outline-none focus:bg-gray-300" />
                        </div>
                      </td>
                      <td>
                        <Field
                          as="select"
                          name="vestingInterval"
                          required
                          onChange={(e) => handleInputChange(e, i)}
                          value={row.vestingInterval}
                          className="p-2 border-1 cursor-pointer outline-none focus:bg-gray-300"
                        >
                          {getDropdownOptions(vestingIntervalList, "--Select Interval--")}
                        </Field>
                      </td>
                      <td>
                        <div className="btn-container">
                          {i !== 0 && (
                            <button
                              className="mr-2"
                              variant="secondary"
                              onClick={() => handleRemoveClick(i)}
                            >
                              <i className="fas fa-trash text-gray-800"></i>
                            </button>
                          )}
                          {formData.tableData.length === i + 1 && formData.totalSupply && showAddButton && (
                            <button
                              className="float-right"
                              variant="secondary"
                              onClick={() => handleAddClick(i)}
                            >
                              <i className="fas fa-plus text-gray-800"></i>
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AdvancedStep;