import React, { useEffect, useState } from "react";
import axios from "axios";
import { URLs } from "constants/urls";
import { userService } from '../../services/user.service';
import { toastNotification } from "common/util_function";
import AirdropForm from "components/Airdrops/Form";
import AirdropList from "components/Airdrops/List";
import Modal from "components/UI/Modal";
import "react-datepicker/dist/react-datepicker.css";

const defaultData = {
  id: 0,
  project: "",
  possibility: "",
  steps: "",
  expectedDate: new Date(),
  isCompleted: null
}
const Airdrops = () => {
  const [logoList, setLogoList] = useState([]);
  const [project, setProject] = useState("");
  const [paginationConfig, setPaginationConfig] = useState({
    limit: 10,
    page: 1,
    total: 0,
  });
  const [airdropList, setAirdropList] = useState([]);
  const [formData, setFormData] = useState(defaultData);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getLogoList();
    list(paginationConfig.limit, paginationConfig.page - 1);
    return () => { }
  }, []);

  const refreshPage = () => {
    list(paginationConfig.limit, ((paginationConfig.page - 1) * paginationConfig.limit));
  }

  const getLogoList = () => {
    const url = URLs.project.all;
    axios
      .get(url)
      .then((response) => {
        if (response.data.code === 200) {
          setLogoList(response.data.data);
        } else {
          setLogoList([]);
          throw new Error(response.data.message);
        }
      })
      .catch((err) => {
        setLogoList([]);
      });
  };

  const list = async (limit, offset, active) => {
    const config = {
      method: "post",
      url: URLs.airdrop.list,
      data: { limit, offset, active },
    };
    setIsLoading(true);
    try {
      const response = await axios(config);
      if (response.data.code === 200) {
        setAirdropList(response.data.data);
        setPaginationConfig({
          ...paginationConfig,
          total: response.data.message,
        });
        setIsLoading(false);
      } else {
        setAirdropList([]);
        setIsLoading(false);
        throw new Error(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      setAirdropList([]);
      toastNotification("error", error.message);
    }
  };

  const onClose = () => {
    document.body.classList.remove("overflow-hidden");
    setIsOpenForm(false);
    window.scrollTo(0, 0);
  };

  const onFetchRecordsHandler = (limit, page) => {
    list(limit, limit * (page - 1));
  };

  const onSubmit = async (fields) => {
    const userId = userService.userId();
    const { possibility, steps, expectedDate, isCompleted, project, id } = fields;
    const config = {
      method: "post",
      url: URLs.airdrop.form,
      data: { possibility, steps: steps.trim(), expectedDate, isCompleted, userId, projectId: project, id },
    };
    try {
      const response = await axios(config);
      if (response.data.code === 200) {
        toastNotification("success", response.data.message);
        refreshPage();
      } else {
        toastNotification("error", response.data.message);
      }
    } catch (error) {
      toastNotification("error", error.message);
    }
    setTimeout(() => {
      onClose();
    }, 500);
  }

  const openForm = (item) => {
    console.log(item);
    document.body.classList.add("overflow-hidden");
    window.scrollTo(0, 0);
    const data = {
      id: item.id,
      project: item.projectId,
      possibility: item.possibility,
      steps: item.steps,
      expectedDate: new Date(item.expectedDate),
      isCompleted: item.isCompleted
    }
    setFormData(data);
    setProject({ id: item.projectId, imageName: item.imageName, name: item.projectName })
    setIsOpenForm(true)
  };

  const onDeleteHandler = (item) => {
    const url = `${URLs.airdrop.delete}?id=${item.id}&active=${item.active}`;
    axios
      .get(url)
      .then((response) => {
        if (response.data.code === 200) {
          console.log(response.data);
          toastNotification("success", response.data.message);
          refreshPage();
        } else {
          toastNotification("error", response.data.message);
          throw new Error(response.data.message);
        }
      })
      .catch((err) => {
        toastNotification("error", "Failed to delete the airdrops");
      });
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-full lg:w-12/12 mt-20 px-4">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-gray-800 text-xl font-bold uppercase">Airdrop List</h6>
              <button type="submit" onClick={() => openForm(defaultData)} className="bg-blue-800 text-white focus:outline-none font-bold py-2 px-4 rounded">Add New </button>
            </div>
          </div>
          <div className="w-full">
            <AirdropList
              airdropList={airdropList}
              paginationConfig={paginationConfig}
              onFetchRecords={onFetchRecordsHandler}
              onEdit={openForm}
              onDelete={onDeleteHandler}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>

      {isOpenForm && (
        <Modal onClose={onClose} className="cb-large">
          <div className="flex justify-between items-center p-4 bg-blue-600">
            <h6 className="text-white text-xl font-bold uppercase">Airdrop Form</h6>
            <button onClick={onClose} className="cursor-pointer border-2 h-6 w-6 flex items-center focus:outline-none justify-center rounded-xl"><i className="fas fa-times text-white"></i></button>
          </div>
          <div className="px-8 py-4">
            <AirdropForm logoList={logoList} formSubmit={onSubmit} formData={formData} project={project} />
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Airdrops;