/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/cb_logo.png";

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-2 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="flex justify-center text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-5 px-0"
            to="/admin/dashboard"
          >
            <img className="max-w-60-px" src={logo} alt="CryptoBuddy" />
          </Link>
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            <hr className="my-4 md:min-w-full" />
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-2 font-bold block " +
                    ((window.location.href.indexOf("/admin/events") !== -1 || (window.location.href.indexOf("/admin/event/form") !== -1))
                      ? "text-blue-500 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/events"
                >
                  <i
                    className={
                      "w-5 fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/events") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>
                  Events
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-2 font-bold block " +
                    ((window.location.href.indexOf("/admin/projects") !== -1 || (window.location.href.indexOf("/admin/project/form") !== -1))
                      ? "text-blue-500 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/projects"
                >
                  <i
                    className={
                      "w-5 fas fa-file mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/projects") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>
                  Projects
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-2 font-bold block " +
                    (window.location.href.indexOf("/admin/featured-projects") !== -1
                      ? "text-blue-500 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/featured-projects"
                >
                  <i
                    className={
                      "w-5 fas fa-file-alt mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/featured-projects") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>
                  Featured Projects
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-2 font-bold block " +
                    (window.location.href.indexOf("/admin/top-launchpads") !== -1
                      ? "text-blue-500 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/top-launchpads"
                >
                  <i
                    className={
                      "w-5 fas fa-file-alt mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/top-launchpads") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>
                  Top Launchpads
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-2 font-bold block " +
                    (window.location.href.indexOf("/admin/airdrops") !== -1
                      ? "text-blue-500 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/airdrops"
                >
                  <i
                    className={
                      "w-5 fas fa-trophy mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/airdrops") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>
                  Airdrops
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-2 font-bold block " +
                    (window.location.href.indexOf("/admin/campaigns") !== -1
                      ? "text-blue-500 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/campaigns"
                >
                  <i
                    className={
                      "w-5 fas fa-bullhorn mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/campaigns") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>
                  Campaings
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-2 font-bold block " +
                    (window.location.href.indexOf("/admin/moonsheet") !== -1
                      ? "text-blue-500 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/moonsheet"
                >
                  <i
                    className={
                      "w-5 fas fa-file mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/moonsheet") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>
                  Moon Sheet
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-2 font-bold block " +
                    (window.location.href.indexOf("/admin/ecosystem") !== -1
                      ? "text-blue-500 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/ecosystem"
                >
                  <i
                    className={
                      "w-5 fas fa-fingerprint mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/ecosystem") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>
                  Eco System
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-2 font-bold block " +
                    (window.location.href.indexOf("/admin/posts") !== -1 || window.location.href.indexOf("/admin/post/form") !== -1
                      ? "text-blue-500 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/posts"
                >
                  <i
                    className={
                      "w-5 fas fa-clipboard-list mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/posts") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>
                  Posts
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-2 font-bold block " +
                    (window.location.href.indexOf("/admin/investors") !== -1
                      ? "text-blue-500 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/investors"
                >
                  <i
                    className={
                      "w-5 fas fa-user mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/investors") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>
                  Investors
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-2 font-bold block " +
                    (window.location.href.indexOf("/admin/category") !== -1
                      ? "text-blue-500 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/category"
                >
                  <i
                    className={
                      "w-5 fas fa-tools mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/category") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>
                  Category
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
