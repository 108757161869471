import React, { useEffect, useState } from "react";
import { URLs } from "constants/urls";
import axios from "axios";

const SalesOn = ({ project }) => {
  const [platformList, setPlatformList] = useState([]);
  useEffect(() => {
    getPlatforms(project.logoID);
  }, [project]);

  const getPlatforms = (projectId) => {
    const url = `${URLs.getPlatformByEventType}?projectId=${projectId}`;
    axios
      .get(url)
      .then((response) => {
        if (response.data.code === 200) {
          setPlatformList(response.data.data);
        } else {
          setPlatformList([]);
          throw new Error(response.data.message);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  return (
    <div className="flex items-center flex-wrap bg-blue-800 p-2">
      <p className="ml-2 text-xl text-yellow uppercase font-semibold">Sale On:</p>
      {platformList.map((item) => {
        return (
          <span
            className="ml-4 text-xl text-opacity-75 text-gray-400 font-semibold"
            key={item.platform_id}
          >
            {item.pform_name}
          </span>
        );
      })}
    </div>
  );
};

export default SalesOn;
