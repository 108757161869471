import React, { Fragment } from "react";
import { baseURL } from "constants/urls";
import "./post.css";
import "../../assets/styles/scrollbar.css";

const PostView = ({ item }) => {
  return (
    <div className="custom-scrollbar max-h-65vh p-4">
      <h2 className="text-xl font-semibold leading-normal text-gray-800 mb-2">{item.title}</h2>
      <div className="text-gray-700 mb-2"><p>{item.excerpt}</p></div>
      <div className="p-2 border-t border-gray-300">
        {item?.thumbnailURL && <Fragment><img className="max-h-250-px" src={`${baseURL}/images/posts/${item.thumbnailURL}`} alt={item.title} />
          <p className="italic text-sm py-2">{item.title}</p></Fragment>}
        <p className="mb-2">Tags: <b>{item.tags}</b></p>
        <div className="post-content"
          dangerouslySetInnerHTML={{ __html: item.content }}
        />
      </div>
    </div>
  );
}

export default PostView;