import React from "react";
import './Tab.css';

const Tab = ({ list, selectedTab, children, theme }) => {
  const tabClickHandler = (item) => {
    selectedTab(item);
  };
  return (
    <div className={`w-full ${theme}`}>
      <div className="flex flex-wrap border-b border-blue-700 border-solid mb-8 tab-header">
        {list.length &&
          list.map((item) => {
            const activeClass = item.active
              ? "active bg-blue-600 text-white"
              : "text-blue-500";
            return (
              <button
                key={item.key}
                className={`${activeClass} text-lg border-no-bottom cursor-pointer border-solid border-blue-700 p-2 text-left outline-none focus:outline-none`}
                type="button"
                onClick={() => tabClickHandler(item)}
              >
                {item.name}
              </button>
            );
          })}
      </div>
      <div className="tab-content">
        {children}
      </div>
    </div>
  );
};

export default Tab;
