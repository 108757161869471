import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import LogoDropdown from "components/UI/LogoDropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AirdropForm = ({ logoList, formSubmit, formData, project }) => {
  const [titleLogo, setTitleLogo] = useState({});

  useEffect(() => {
    if (project?.id) {
      setTitleLogo(project)
    }
  }, [project])

  const validationSchema = Yup.object().shape({
    project: Yup.string().required('Please select the project'),
    steps: Yup.string().required('Please enter the required steps'),
    expectedDate: Yup.string().required('Please enter the expected time')
  });

  const onSubmit = async (fields, { setStatus, setSubmitting }) => {
    setSubmitting(true);
    setStatus(true);
    formSubmit(fields);
  }
  return (
    <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={onSubmit} >
      {({ errors, values, setFieldValue, setFieldTouched, isSubmitting, handleReset }) => (
        <Form noValidate>
          <div className="px-4 py-2 flex flex-wrap items-center justify-between">
            <label className="block text-xs mb-2 uppercase font-bold">Project</label>
            <div className="w-1/2">
              <LogoDropdown
                className="w-full"
                list={logoList}
                selectedProjectItem={titleLogo}
                onLogo={(item) => {
                  setTitleLogo(item);
                  setFieldValue('project', item.id)
                }}
                name="project"
                value={values.project}
                onChange={e => setFieldValue('project', e)}
              />
              <ErrorMessage name="project" component="div" className="text-red-500 text-sm" />
            </div>
          </div>
          <Field name="id" type="hidden" value={values.id} />
          <div className="px-4 py-2 flex flex-wrap items-center justify-between">
            <label className="block text-xs mb-2 uppercase font-bold">Possibility</label>
            <Field as="select" name="possibility" className="w-1/2 p-2 border-2 cursor-pointer outline-none focus:bg-gray-300">
              <option value="">--Select Option--</option>
              <option value="very-high">Very High</option>
              <option value="high">High</option>
              <option value="medium">Medium</option>
              <option value="low">Low</option>
            </Field>
          </div>

          <div className="px-4 py-2 flex items-center justify-between">
            <label className="block text-xs mb-2 uppercase font-bold">Action Required</label>
            <div className="w-1/2">
              <Field as="textarea" name="steps" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Enter the required steps" autoComplete="off" value={values.steps} />
              <ErrorMessage name="steps" component="div" className="text-red-500 text-sm" />
            </div>
          </div>

          <div className="px-4 py-2 flex items-center justify-between">
            <label className="block text-xs mb-2 uppercase font-bold">Expected Date</label>
            <div className="w-1/2">
              <DatePicker
                autoComplete="off"
                className="w-full p-2 border-2 outline-none focus:bg-gray-300"
                name="expectedDate"
                selected={values.expectedDate}
                onChange={(date) => {
                  console.log(values.expectedDate)
                  setFieldValue("expectedDate", date);
                }}
                dateFormat="MMM-yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
                showFourColumnMonthYearPicker
                placeholderText="Month-Year"
                wrapperClassName="block-important"
              />
              <ErrorMessage name="expectedDate" component="div" className="text-red-500 text-sm" />
            </div>
          </div>

          <div className="px-4 py-2 flex items-center justify-between">
            <label className="block text-xs mb-2 uppercase font-bold">Status</label>
            <div role="group" aria-labelledby="my-radio-group" className="w-1/2 p-2 border-2 flex items-center">
              <label className="cursor-pointer mr-4 inline-flex items-center">
                <Field type="radio" name="isCompleted" value="1" className="form-radio h-5 w-5 text-gray-600" />
                <span className="ml-2 text-gray-700">Active</span>
              </label>
              <label className="cursor-pointer mr-4 inline-flex items-center">
                <Field type="radio" name="isCompleted" value="2" className="form-radio h-5 w-5 text-gray-600" />
                <span className="ml-2 text-gray-700">Completed</span>
              </label>
            </div>
          </div>

          <div className="px-4 py-2 flex items-center justify-between">
            <span>&nbsp;</span>
            <div className="w-1/2 flex flex-row justify-between">
              <button type="reset" className="bg-gray-300 text-gray-100 focus:outline-none font-bold py-2 px-6 rounded" onClick={() => {
                handleReset();
                setTitleLogo({});
              }}>Clear</button>

              <button type="submit" className="bg-blue-800 text-white focus:outline-none font-bold py-2 px-4 rounded">{isSubmitting && <span className="spinner-border spinner-border-sm mr-1">wait..</span>} Submit </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default AirdropForm;