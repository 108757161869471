import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { URLs } from "constants/urls";
import EventList from "components/Events/List";
import { toastNotification } from "common/util_function";
import EventFilter from "components/Events/Filter";
import { Link } from "react-router-dom";

const defaultData = {
  projectId: '',
  platformId: '',
  fromDate: '',
  toDate: '',
  rador: false
}

const initialPageConfig = {
  limit: 10,
  page: 1,
  total: 0,
};

const Events = () => {
  const [platformList, setPlatformList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [results, setResults] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState(initialPageConfig);
  const [filterData, setFilterData] = useState(defaultData);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getProjectList();
    list(paginationConfig.limit, paginationConfig.page - 1);
    return () => { }
  }, []);

  const list = useCallback(async (limit, offset, data, page = 1) => {
    const config = {
      method: "post",
      url: URLs.events.list,
      data: { limit, offset, ...data },
    };
    setIsLoading(true)
    try {
      const response = await axios(config);
      if (response.data.code === 200) {
        setResults(response.data.data);
        setPaginationConfig({
          ...paginationConfig,
          page,
          total: response.data.message,
        });
        setIsLoading(false)
      } else {
        setResults([]);
        setIsLoading(false)
        throw new Error(response.data.message);
      }
    } catch (error) {
      setIsLoading(false)
      toastNotification("error", error.message);
    }
  }, [paginationConfig]);

  const getProjectList = () => {
    const url = URLs.project.all;
    axios
      .get(url)
      .then((response) => {
        if (response.data.code === 200) {
          const results = response.data.data;
          const projects = results.filter(item => +item.isLaunchpad === 0);
          const platforms = results.filter(item => +item.isLaunchpad === 1);
          setProjectList(projects);
          setPlatformList(platforms);
        } else {
          setProjectList([]);
          setPlatformList([]);
        }
      })
      .catch((err) => {
        setProjectList([]);
        setPlatformList([]);
      });
  };

  const onFetchRecordsHandler = (limit, page) => {
    list(limit, limit * (page - 1), filterData, page);
  };

  const onFilterHandler = (data) => {
    setFilterData(data);
    list(paginationConfig.limit, 0, data, 1);
  }

  const onDeleteHandler = (item) => {
    const url = `${URLs.events.delete}?id=${item.id}&status=${item.active}`;
    axios
      .get(url)
      .then((response) => {
        if (response.data.code === 200) {
          toastNotification("success", response.data.message);
          list(paginationConfig.limit, paginationConfig.page - 1);
        } else {
          toastNotification("error", response.data.message);
          throw new Error(response.data.message);
        }
      })
      .catch((err) => {
        toastNotification("error", "Failed to delete the event!, Please try again.");
      });
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-full lg:w-12/12 mt-20 px-4">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-gray-800 text-xl font-bold uppercase">Events List</h6>
              <Link
                className="bg-blue-800 text-white focus:outline-none font-bold py-2 px-4 rounded"
                to="/admin/event/form"
              >
                Add New
              </Link>
            </div>
          </div>
          <div className="w-full">
            <EventFilter formData={filterData} projectList={projectList} platformList={platformList} onFilter={onFilterHandler} />
            <EventList
              results={results}
              paginationConfig={paginationConfig}
              onFetchRecords={onFetchRecordsHandler}
              onDelete={onDeleteHandler}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;