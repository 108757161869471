import { baseURL } from "constants/urls";
import React from "react";
import "./ProjectLogo.css";

const ProjectLogo = ({ className, logo, path = "projects", isHideLabel = false, width = 24, height = 24 }) => {
  return (
    <div className={`project-logo ${className ? className : ""}`}>
      {logo?.imageName !== undefined && (
        <React.Fragment>
          <img
            className="img"
            alt={logo.name}
            src={`${baseURL}/images/${path}/${logo.imageName}`}
            width={width}
            height={height}
          />
          {isHideLabel !== "true" && <span>{logo.name}</span>}
        </React.Fragment>
      )}
    </div>
  );
};

export default ProjectLogo;
