const initialState = { isAuthenticated: false, user: {}, userID: "" };

const userReducer = (state = initialState, action) => {
  //console.log(action.type);
  //console.log(action.data);
  switch (action.type) {
    case "SET_USER_DATA":
      localStorage.setItem("user", JSON.stringify(action.data));
      return Object.assign({}, state, {
        user: action.data,
      });
    case "SET_IS_AUTHENTICATED":
      localStorage.setItem("isAuthenticated", action.data);
      return Object.assign({}, state, {
        isAuthenticated: action.data,
      });
    case "SET_USER_ID":
      localStorage.setItem("userID", action.data);
      return Object.assign({}, state, {
        userID: action.data,
      });
    default:
      return state;
  }
};

export default userReducer;
