import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import DateFormat from "../UI/DateFormat";
import Status from "../UI/Status";
import Modal from "../UI/Modal";
import Pagination from "../UI/Pagination";
import Loading from "components/UI/Loading";
import ConfirmModal from "components/UI/ConfirmModal";
import PostView from "./View";
import { Link } from "react-router-dom";

const PostList = ({ paginationConfig, onEdit, onFetchRecords, results, onDelete, isLoading }) => {
  const [selectedItem, setSelectedItem] = useState("");
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onDeleteHandler = (item) => {
    setSelectedItem(item);
    setDeleteStatus(item.active);
    document.body.classList.add("overflow-hidden");
    window.scrollTo(0, 0);
    setShowConfirmModal(true);
  };

  const onClose = () => {
    document.body.classList.remove("overflow-hidden");
    setShowConfirmModal(false);
    setShowModal(false);
  };

  const onNavigationHandler = (limit, page) => {
    onFetchRecords(limit, page);
  };

  const confirmHandler = (action) => {
    document.body.classList.remove("overflow-hidden");
    setShowConfirmModal(false);
    if (action) {
      onDelete(selectedItem);
    }
  };

  const onViewHandler = (item) => {
    setSelectedItem(item);
    document.body.classList.add("overflow-hidden");
    window.scrollTo(0, 0);
    setShowModal(true);
  };

  return (
    <div className="p-4">
      {/* <Editor /> */}

      <Pagination
        className="mb-3"
        paginationConfig={paginationConfig}
        onNavigation={onNavigationHandler}
      />
      {showConfirmModal && (
        <Modal onClose={onClose} className="cb-small">
          <ConfirmModal
            description={`Are you sure want to ${+deleteStatus ? "de-activate" : "activate"
              }?`}
            onConfirmModal={confirmHandler}
          />
        </Modal>
      )}
      {showModal && (
        <Modal onClose={onClose} className="cb-large">
          <PostView item={selectedItem} />
        </Modal>
      )}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th width="100px">Id</th>
            <th>Post Title</th>
            {/* <th width="25%">Content</th> */}
            <th>Category</th>
            <th>Tags</th>
            <th>Updated On</th>
            <th>Updated By</th>
            <th>Active</th>
            <th width="115px">Action</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? <tr key="loading"><td colSpan={9}><Loading></Loading></td></tr> :
            results.map((item) => {
              return (
                <tr key={item.id}>
                  <td>#P{item.id}</td>
                  <td>{item.title}</td>
                  <td>{item.category}</td>
                  <td>{item.tags}</td>
                  <td><DateFormat date={Date.parse(item.modifiedAt)} /></td>
                  <td>{item.username}</td>
                  <td><Status value={item.active} /></td>
                  <td>
                    <div className="btn-container">
                      <Button
                        className="mr-2"
                        variant="secondary"
                        onClick={() => onDeleteHandler(item)}
                      >
                        <i className="fas fa-trash text-gray-400"></i>
                      </Button>
                      <Button
                        className="mr-2"
                        variant="secondary"
                        onClick={() => onViewHandler(item)}
                      >
                        <i className="fas fa-desktop text-gray-400"></i>
                      </Button>
                      <Link
                        to={{
                          pathname: "/admin/post/form",
                          data: item
                        }}
                      >
                        <Button className="float-right" variant="secondary">
                          <i className="fas fa-edit text-gray-400"></i>
                        </Button>
                      </Link>
                    </div>
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </Table>

      <Pagination
        paginationConfig={paginationConfig}
        onNavigation={onNavigationHandler}
      />
    </div>
  );
};


export default PostList;