import React from "react";
import "./Logo.css";

import logo from "../../assets/images/cb_ln.png";


const Logo = (props) => {
  return (
    <div className={`logo ${props.className ? props.className : ""}`}>
      <img src={logo} alt="CryptoBuddy" />
    </div>
  );
};

export default Logo;
