import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import "../../assets/styles/scrollbar.css";
import LogoDropdown from "components/UI/LogoDropdown";

const FeaturedForm = ({ formSubmit, formData, projectList }) => {
  const [selectedProjectLogo, setSelectedProjectLogo] = useState();

  useEffect(() => {
    if (formData.projectId) {
      const project = projectList.find(item => item.id === formData.projectId);
      setSelectedProjectLogo(project);
    }
    return () => { }
  }, [formData, projectList])

  const validationSchema = Yup.object().shape({
    projectId: Yup.string().required('Please select the project')
  });

  const onSubmit = async (fields) => {
    await formSubmit(fields);
  }

  return (
    <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={onSubmit} >
      {({ errors, values, setFieldValue, setFieldTouched, isSubmitting, resetForm }) => (
        <Form noValidate>
          <div className="custom-scrollbar px-8 py-4 max-h-65vh">
            <div className="w-full px-3">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                  Project
                </label>
                <LogoDropdown
                  list={projectList}
                  selectedProjectItem={selectedProjectLogo}
                  onLogo={(item) => {
                    setSelectedProjectLogo(item);
                    setFieldValue('projectId', item.id)
                  }}
                  name="projectId"
                  value={values.projectId}
                  absolute={false}
                />
                <ErrorMessage name="projectId" className="text-red-500 text-sm" />
              </div>
            </div>

          </div>
          <div className="px-4 py-2 border-t-2 flex justify-end">
            <button type="reset" className="bg-gray-300 text-gray-100 focus:outline-none font-bold py-2 px-6 rounded mr-2" onClick={() => setSelectedProjectLogo("")}>Clear</button>
            <button type="submit" className="bg-blue-800 text-white focus:outline-none font-bold py-2 px-4 rounded">{isSubmitting && <span className="spinner-border spinner-border-sm mr-1">wait..</span>} Submit </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default FeaturedForm;