import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { URLs } from "constants/urls";
import { toastNotification } from "common/util_function";
import PostList from "components/Post/List";
import { Link } from "react-router-dom";

const initialPageConfig = {
  limit: 10,
  page: 1,
  total: 0,
};

const Posts = () => {
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState(initialPageConfig);

  useEffect(() => {
    list(paginationConfig.limit, paginationConfig.page - 1);
    return () => { }
  }, []);

  const list = useCallback(async (limit, offset, data) => {
    const config = {
      method: "post",
      url: URLs.posts.list,
      data: { limit, offset, ...data },
    };
    setIsLoading(true);
    try {
      const response = await axios(config);
      if (response.data.code === 200) {
        setResults(response.data.data);
        setIsLoading(false);
        setPaginationConfig({
          ...paginationConfig,
          page: offset === 0 ? 1 : paginationConfig.page,
          total: response.data.message,
        });
      } else {
        setResults([]);
        setIsLoading(false);
        throw new Error(response.data.message);
      }
    } catch (error) {
      toastNotification("error", error.message);
      setResults([]);
    }
  }, [paginationConfig]);

  const onFetchRecordsHandler = useCallback((limit, page) => {
    list(limit, limit * (page - 1));
  });

  const onDeleteHandler = (item) => {
    const url = `${URLs.posts.delete}?id=${item.id}&status=${item.active}`;
    axios
      .get(url)
      .then((response) => {
        if (response.data.code === 200) {
          toastNotification("success", response.data.message);
          list(paginationConfig.limit, paginationConfig.page - 1);
        } else {
          toastNotification("error", response.data.message);
          throw new Error(response.data.message);
        }
      })
      .catch((err) => {
        toastNotification("error", "Failed to delete the posts");
      });
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-full lg:w-12/12 mt-20 px-4">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-gray-800 text-xl font-bold uppercase">Post List</h6>
              <Link
                className="bg-blue-800 text-white focus:outline-none font-bold py-2 px-4 rounded"
                to="/admin/post/form"
              >
                Add New
              </Link>
            </div>
          </div>
          <div className="w-full">
            <PostList
              results={results}
              paginationConfig={paginationConfig}
              onFetchRecords={onFetchRecordsHandler}
              onDelete={onDeleteHandler}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Posts;