import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ProjectLogo from "components/UI/ProjectLogo";

const EcoSystemForm = ({ formSubmit, formData, logo }) => {
  const [file, setFile] = useState("");

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please enter the ecosystem name')
  });

  const onSubmit = async (fields, { setStatus, setSubmitting }) => {
    setSubmitting(true);
    setStatus(true);
    formSubmit(fields, file);
  }
  return (
    <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={onSubmit} >
      {({ values, isSubmitting, handleReset }) => (
        <Form noValidate>
          <Field name="id" type="hidden" value={values.id} />

          <div className="px-4 py-2 flex items-center justify-between">
            <label className="block text-xs mb-2 uppercase font-bold">BlockChain Name</label>
            <div className="w-1/2">
              <Field type="text" name="name" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="EcoSystem Name" autoComplete="off" value={values.name} />
              <ErrorMessage name="name" component="div" className="text-red-500 text-sm" />
            </div>
          </div>

          <div className="px-4 py-2 flex items-center justify-between">
            <label className="block text-xs mb-2 uppercase font-bold">Upload Image</label>
            <div className="w-1/2 flex items-center justify-between">
              {values?.fileName && <ProjectLogo className="mr-2" logo={logo} />}
              <Field type="file" name="imageFile" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" autoComplete="off" onChange={(e) => {
                setFile(e.target.files[0]);
              }
              } />
              <ErrorMessage name="imageFile" component="div" className="text-red-500 text-sm" />
            </div>
          </div>

          <div className="px-4 py-2 flex items-center justify-between">
            <span>&nbsp;</span>
            <div className="w-1/2 flex flex-row justify-between">
              <button type="reset" className="bg-gray-300 text-gray-100 focus:outline-none font-bold py-2 px-6 rounded" onClick={() => {
                handleReset();
              }}>Clear</button>

              <button type="submit" className="bg-blue-800 text-white focus:outline-none font-bold py-2 px-4 rounded">{isSubmitting && <span className="spinner-border spinner-border-sm mr-1">wait..</span>} Submit </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default EcoSystemForm;