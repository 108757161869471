import React from "react";

const SocialLink = () => {
  const socailData = [
    {
      name: "cryptobuddy.info",
      icon: "globe",
      fa: "fas",
      link: "https://cryptobuddy.info",
    },
    {
      name: "@cryptobuddy_info",
      icon: "twitter",
      fa: "fab",
      link: "https://twitter.com/CryptobuddyInfo",
    },
    {
      name: "@cryptobuddyinfo",
      icon: "telegram",
      fa: "fab",
      link: "http://t.me/cryptobuddy_info",
    },
  ];
  return (
    <ul className="flex items-center p-2">
      {socailData.map((item) => {
        return (
          <li key={item.icon}>
            <a href={item.link} rel="noopener noreferrer" target="_blank" className="flex items-center">
              <span
                className="flex bg-white text-blue-400 shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-1"
                type="button"
              >
                <i className={`${item.fa} fa-${item.icon}`}></i>
              </span>
              <span className="mr-2 text-white">{item.name}</span>
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default SocialLink;
