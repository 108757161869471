const initialState = { timeZone: "UTC" };

const timezoneReducer = (state = initialState, action) => {
  //console.log(action.type);
  switch (action.type) {
    case "SET_TIMEZONE":
      localStorage.setItem("timeZone", action.data);
      return Object.assign({}, state, {
        timeZone: action.data,
      });
    default:
      return state;
  }
};

export default timezoneReducer;
