import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Multiselect } from "multiselect-react-dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ProjectLogo from "components/UI/ProjectLogo";

const CampaignForm = ({ formSubmit, formData, ecosystems, categoryList }) => {
  const [bannerImageFile, setBannerImageFile] = useState("");
  const [thumbImageFile, setThumbImageFile] = useState("");
  const [adsImageFile, setAdsImageFile] = useState("");
  const [slug, setSlug] = useState("");
  const multiStyle = {
    chips: { background: "gray-300" },
    searchBox: {
      border: "none",
      borderRadius: "0px",
      padding: "0.5rem 1rem"
    },
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please enter the name'),
    slug: Yup.string().required('Please enter the slug name'),
    description: Yup.string().required('Please enter the description'),
    // otherFields: Yup.string().required('Please enter the fields'),
    // blockChain: Yup.string().required('Please select a ecosystem'),
    // categoryId: Yup.string().required('Please select a category'),
    // endDate: Yup.string().required('Please enter the end date')
  });


  const onSubmit = async (fields, { setStatus, setSubmitting }) => {
    setSubmitting(true);
    setStatus(true);
    formSubmit(fields, { bannerImageFile, thumbImageFile, adsImageFile });
  }

  const handleChangeName = (event) => {
    const tempArray = event.target.value.trim().replace(/[^a-zA-Z0-9,\s]/g, '').replace(/[\s-]+/g, '-').toLowerCase();
    setSlug(tempArray.split('-').slice(0, 4).join('-'));
  }

  return (
    <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={onSubmit} >
      {({ errors, values, setFieldValue, setFieldTouched, isSubmitting, handleReset }) => (
        <Form noValidate>
          <div className="custom-scrollbar max-h-65vh">
            <Field name="id" type="hidden" value={values.id} />
            <div className="px-4 py-2 flex flex-wrap items-center justify-between">
              <label className="flex items-center text-sm mb-2 font-bold">Campaign Name<i className="text-red-500 fas fa-star ml-1 text-7px opacity-75"></i></label>
              <div className="w-1/2">
                <Field type="text" name="name" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Campaign Name" autoComplete="off" value={values.name} onKeyUp={handleChangeName} />
                <ErrorMessage name="name" component="div" className="text-red-500 text-sm" />
              </div>
            </div>

            <div className="px-4 py-2 flex flex-wrap items-center justify-between">
              <label className="block text-sm mb-2 font-bold">Slug Name</label>
              <div className="w-1/2">
                <Field type="text" name="slug" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Slug Name" autoComplete="off" value={values?.slug ? values.slug : slug} />
                <ErrorMessage name="slug" component="div" className="text-red-500 text-sm" />
              </div>
            </div>

            <div className="px-4 py-2 flex items-center justify-between">
              <label className="flex items-center text-sm mb-2 font-bold">Description<i className="text-red-500 fas fa-star ml-1 text-7px opacity-75"></i></label>
              <div className="w-1/2">
                <Field as="textarea" name="description" className="text-sm w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Description" autoComplete="off" value={values.description} />
                <ErrorMessage name="description" component="div" className="text-red-500 text-sm" />
              </div>
            </div>

            <div className="px-4 py-2 flex items-center justify-between">
              <label className="block text-sm mb-2 font-bold">Banner Image</label>
              <div className="w-1/2 flex items-center justify-between">
                {values?.imageURL && <ProjectLogo className="mr-2" logo={{ imageName: values?.imageURL, name: "" }} path="campaigns" />}
                <Field type="file" name="bannerImage" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" autoComplete="off" onChange={(e) => {
                  setBannerImageFile(e.target.files[0]);
                }
                } />
                <ErrorMessage name="bannerImage" component="div" className="text-red-500 text-sm" />
              </div>
            </div>

            <div className="px-4 py-2 flex items-center justify-between">
              <label className="block text-sm mb-2 font-bold">Thumbnail Image</label>
              <div className="w-1/2 flex items-center justify-between">
                {values?.thumbImageURL && <ProjectLogo className="mr-2" logo={{ imageName: values?.thumbImageURL, name: "" }} path="campaigns" />}
                <Field type="file" name="thumbImage" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" autoComplete="off" onChange={(e) => {
                  setThumbImageFile(e.target.files[0]);
                }
                } />
                <ErrorMessage name="thumbImage" component="div" className="text-red-500 text-sm" />
              </div>
            </div>

            <div className="px-4 py-2 flex items-center justify-between">
              <label className="block text-sm mb-2 font-bold">Ads Banner Image</label>
              <div className="w-1/2 flex items-center justify-between">
                {values?.adsImageURL && <ProjectLogo className="mr-2" logo={{ imageName: values?.adsImageURL, name: "" }} path="campaigns" />}
                <Field type="file" name="adsImage" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" autoComplete="off" onChange={(e) => {
                  setAdsImageFile(e.target.files[0]);
                }
                } />
                <ErrorMessage name="adsImage" component="div" className="text-red-500 text-sm" />
              </div>
            </div>

            <div className="px-4 py-2 flex items-center justify-between">
              <label className="flex items-center text-sm mb-2 font-bold">Fields<i className="text-red-500 fas fa-star ml-1 text-7px opacity-75"></i></label>
              <div className="w-1/2">
                <Field as="textarea" name="otherFields" className="w-full text-sm p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Fields" autoComplete="off" value={values.otherFields} />
                <ErrorMessage name="otherFields" component="div" className="text-red-500 text-sm" />
              </div>
            </div>

            <div className="px-4 py-2 flex flex-wrap items-center justify-between">
              <label className="flex items-center text-sm mb-2 font-bold">End Date UTC Time<i className="text-red-500 fas fa-star ml-1 text-7px opacity-75"></i></label>
              <div className="w-1/2 full-width">
                <DatePicker name="endDate" autoComplete="off" selected={values.endDate} onChange={(date) => {
                  setFieldValue("endDate", date);
                }} className="w-full p-2 border-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  isClearable
                  placeholderText="MM/DD/YYYY"
                  showTimeInput
                  timeInputLabel="From Time:"
                  dateFormat="MM/dd/yyyy hh:mm" />
              </div>
            </div>

            <div className="px-4 py-2 flex items-center justify-between">
              <label className="flex items-center text-sm mb-2 font-bold">BlockChain<i className="text-red-500 fas fa-star ml-1 text-7px opacity-75"></i></label>
              <div className="w-1/2 max-h-100-px border-2 cursor-pointer outline-none focus:bg-gray-300">
                <Multiselect
                  options={ecosystems}
                  displayValue="name"
                  showCheckbox={true}
                  placeholder="Select Option"
                  name="blockChains"
                  closeOnSelect={false}
                  style={multiStyle}
                  selectedValues={values.blockChains}
                  hidePlaceholder={true}
                  onSelect={(items) => setFieldValue("blockChains", items)}
                  onRemove={(items) => setFieldValue("blockChains", items)}
                />
                <ErrorMessage name="blockChains" component="div" className="text-red-500 text-sm" />
              </div>
            </div>

            <div className="px-4 py-2 flex flex-wrap items-center justify-between">
              <label className="flex items-center text-sm mb-2 font-bold">Category <i className="text-red-500 fas fa-star ml-1 text-7px opacity-75"></i></label>
              <div className="w-1/2">
                <Field as="select" name="categoryId" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300">
                  <option value="">--Select Option--</option>
                  {
                    categoryList.length && categoryList.map(item => {
                      return <option key={item.id} value={item.id}>{item.name}</option>
                    })
                  }
                </Field>
              </div>
            </div>

            <div className="px-4 py-2 flex flex-wrap items-center justify-between">
              <label className="flex items-center text-sm mb-2 font-bold">Retweet URL <i className="text-red-500 fas fa-star ml-1 text-7px opacity-75"></i></label>
              <div className="w-1/2">
                <Field type="text" name="retweetURL" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Retweet URL" autoComplete="off" value={values.retweetURL} />
                <ErrorMessage name="retweetURL" component="div" className="text-red-500 text-sm" />
              </div>
            </div>

            <div className="px-4 py-2 flex flex-wrap items-center justify-between">
              <label className="block text-sm mb-2 font-bold">Read More URL<i className="text-red-500 fas fa-star ml-1 text-7px opacity-75"></i></label>
              <div className="w-1/2">
                <Field type="text" name="readMoreURL" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Read More URL" autoComplete="off" value={values.readMoreURL} />
                <ErrorMessage name="readMoreURL" component="div" className="text-red-500 text-sm" />
              </div>
            </div>
          </div>

          <div className="px-4 py-2 border-t-2 flex items-center justify-between">
            <span>&nbsp;</span>
            <div className="w-1/2 flex flex-row justify-between">
              <button type="reset" className="bg-gray-300 text-gray-100 focus:outline-none font-bold py-2 px-6 rounded" onClick={() => {
                handleReset();
              }}>Clear</button>

              <button type="submit" className="bg-blue-800 text-white focus:outline-none font-bold py-2 px-4 rounded">{isSubmitting && <span className="spinner-border spinner-border-sm mr-1">wait..</span>} Submit </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CampaignForm;