import React from "react";
import ProjectLogo from "components/UI/ProjectLogo";
import { getecoSystemvalues } from "../../common/util_function";

const ProjectView = ({ item, ecosystem }) => {
  console.log(item)
  return (
    <div className="overflow-y-auto max-h-65vh border mx-4">
      <div className="px-4 py-2 flex items-center justify-between border-b">
        <label className="block text-xs mb-2 uppercase font-bold">Project Name</label>
        <div className="w-1/2">
          <ProjectLogo className="mr-2" logo={{ logoFile: item?.logoFileName, logoName: item?.projectName }} />
        </div>
      </div>
      <div className="px-4 py-2 flex items-center justify-between border-b">
        <label className="block text-xs mb-2 uppercase font-bold">Description</label>
        <div className="w-1/2">
          {item.description}
        </div>
      </div>
      <div className="px-4 py-2 flex items-center justify-between border-b">
        <label className="block text-xs mb-2 uppercase font-bold">Launchpad</label>
        <div className="w-1/2">
          {item.isLaunchpad === 1 ? "Yes" : "No"}
        </div>
      </div>
      <div className="px-4 py-2 flex items-center justify-between border-b">
        <label className="block text-xs mb-2 uppercase font-bold">Token Name</label>
        <div className="w-1/2">
          {item.tokenName ? item.tokenName : '-'}
        </div>
      </div>
      <div className="px-4 py-2 flex items-center justify-between border-b">
        <label className="block text-xs mb-2 uppercase font-bold">BlockChain (EcoSystem)</label>
        <div className="w-1/2">
          {item.blockChain ? getecoSystemvalues(item.blockChain, ecosystem) : '-'}
        </div>
      </div>

      <div className="px-4 py-2 flex items-center justify-between border-b">
        <label className="block text-xs mb-2 uppercase font-bold">Telegram Announcement URL</label>
        <div className="w-1/2">
          {item.telegramAnnouncementURL ? item.telegramAnnouncementURL : '-'}
        </div>
      </div>
      <div className="px-4 py-2 flex items-center justify-between border-b">
        <label className="block text-xs mb-2 uppercase font-bold">Telegram Community URL</label>
        <div className="w-1/2">
          {item.telegramCommunityURL ? item.telegramCommunityURL : '-'}
        </div>
      </div>
      <div className="px-4 py-2 flex items-center justify-between border-b">
        <label className="block text-xs mb-2 uppercase font-bold">Twitter URL</label>
        <div className="w-1/2">
          {item.twitterURL ? item.twitterURL : '-'}
        </div>
      </div>
      <div className="px-4 py-2 flex items-center justify-between border-b">
        <label className="block text-xs mb-2 uppercase font-bold">Website URL</label>
        <div className="w-1/2">
          {item.websiteURL ? item.websiteURL : '-'}
        </div>
      </div>
      <div className="px-4 py-2 flex items-center justify-between border-b">
        <label className="block text-xs mb-2 uppercase font-bold">Discord URL</label>
        <div className="w-1/2">
          {item.discordURL ? item.discordURL : '-'}
        </div>
      </div>
      <div className="px-4 py-2 flex items-center justify-between border-b">
        <label className="block text-xs mb-2 uppercase font-bold">Github URL</label>
        <div className="w-1/2">
          {item.githubURL ? item.githubURL : '-'}
        </div>
      </div>
      <div className="px-4 py-2 flex items-center justify-between border-b">
        <label className="block text-xs mb-2 uppercase font-bold">Medium URL</label>
        <div className="w-1/2">
          {item.mediumURL ? item.mediumURL : '-'}
        </div>
      </div>
    </div>
  );
}

export default ProjectView;