import { BehaviorSubject } from 'rxjs';
import { URLs } from 'constants/urls';
import { fetchWrapper } from '../helpers';
import axios from "axios";
const userSubject = new BehaviorSubject(localStorage.getItem('jwtToken'));

const login = (username, password) => {
  const config = {
    method: "post",
    url: URLs.loginURL,
    data: { username, password },
  };

  return axios(config)
    .then((response) => {
      if (response.data.code === 200) {
        const user = response.data.data;
        userSubject.next(user);
        startRefreshTokenTimer();
        localStorage.setItem('jwtToken', JSON.stringify(user));
        return { type: "SUCCESS", data: user, message: response.data.message };
      } else {
        if (response?.data?.data?.type === "resend") {
          return { type: "FAILED", data: response.data.data, message: response.data.message };
        } else {
          throw new Error(response.data.message);
        }
      }
    })
    .catch((error) => {
      return { type: "FAILED", data: [], message: error.message };
    });
}

const readJwtToken = (token) => {
  return token ? JSON.parse(atob(token.split('.')[1])) : "";
}

const logout = () => {
  // revoke token, stop refresh timer, publish null to user subscribers and redirect to login page
  // fetchWrapper.post(`${URLs.apiUrl}/revoke-token`, {});
  stopRefreshTokenTimer();
  localStorage.removeItem('jwtToken');
  userSubject.next(null);
}

const refreshToken = () => {
  const config = {
    method: "post",
    url: URLs.refreshTokenURL,
    data: { jwtToken: userSubject.value },
  };

  return axios(config)
    .then((response) => {
      if (response.data.code === 200) {
        const user = response.data.data;
        if (user) {
          userSubject.next(user);
          startRefreshTokenTimer();
          localStorage.setItem('jwtToken', JSON.stringify(user));
          return { type: "SUCCESS", data: user, message: response.data.message };
        } else {
          stopRefreshTokenTimer();
          userSubject.next(null);
          return { type: "FAILED", data: [], message: "" };
        }
      } else {
        throw new Error(response.data.message);
      }
    })
    .catch((error) => {
      return { type: "FAILED", data: [], message: error.message };
    });
}

const register = (params) => {
  const config = {
    method: "post",
    url: URLs.register,
    data: params,
  };

  return axios(config)
    .then((response) => {
      if (response.data.code === 200) {
        const user = response.data.data;
        return { type: "SUCCESS", data: user, message: response.data.message };
      } else {
        throw new Error(response.data.message);
      }
    })
    .catch((error) => {
      return { type: "FAILED", data: [], message: error.message };
    });
}

const resendEmail = (email) => {
  const config = {
    method: "post",
    url: URLs.resendEmailURL,
    data: { username: email },
  };

  return axios(config)
    .then((response) => {
      if (response.data.code === 200) {
        const user = response.data.data;
        return { type: "SUCCESS", data: user, message: response.data.message };
      } else {
        throw new Error(response.data.message);
      }
    })
    .catch((error) => {
      return { type: "FAILED", data: [], message: error.message };
    });
}

const verifyEmail = (token) => {
  const config = {
    method: "post",
    url: URLs.verifyEmailURL,
    data: { token: token },
  };

  return axios(config)
    .then((response) => {
      if (response.data.code === 200) {
        const user = response.data.data;
        return { type: "SUCCESS", data: user, message: response.data.message };
      } else {
        throw new Error(response.data.message);
      }
    })
    .catch((error) => {
      return { type: "FAILED", data: [], message: error.message };
    });
}

const forgotPassword = (email) => {
  const config = {
    method: "post",
    url: URLs.forgotPasswordURL,
    data: { username: email },
  };

  return axios(config)
    .then((response) => {
      if (response.data.code === 200) {
        const user = response.data.data;
        return { type: "SUCCESS", data: user, message: response.data.message };
      } else {
        throw new Error(response.data.message);
      }
    })
    .catch((error) => {
      return { type: "FAILED", data: [], message: error.message };
    });
}

const validateResetToken = (token) => {
  // return fetchWrapper.post(`${URLs.apiUrl}/validate-reset-token`, { token });
}

const resetPassword = (params) => {
  const config = {
    method: "post",
    url: URLs.resetPasswordURL,
    data: params,
  };

  return axios(config)
    .then((response) => {
      if (response.data.code === 200) {
        const user = response.data.data;
        return { type: "SUCCESS", data: user, message: response.data.message };
      } else {
        throw new Error(response.data.message);
      }
    })
    .catch((error) => {
      return { type: "FAILED", data: [], message: error.message };
    });
}

const changePassword = (params) => {
  const config = {
    method: "post",
    url: URLs.changePasswordURL,
    data: { ...params, jwtToken: userSubject.value },
  };

  return axios(config)
    .then((response) => {
      if (response.data.code === 200) {
        const user = response.data.data;
        return { type: "SUCCESS", data: user, message: response.data.message };
      } else {
        throw new Error(response.data.message);
      }
    })
    .catch((error) => {
      return { type: "FAILED", data: [], message: error.message };
    });
}

const getAll = () => {
  return fetchWrapper.get(URLs.apiUrl);
}

const getById = (id) => {
  return fetchWrapper.get(`${URLs.apiUrl}/${id}`);
}

const create = (params) => {
  return fetchWrapper.post(URLs.apiUrl, params);
}

const update = (id, params) => {
  return fetchWrapper.put(`${URLs.apiUrl}/${id}`, params)
    .then(user => {
      // update stored user if the logged in user updated their own record
      if (user.id === userSubject.value.id) {
        // publish updated user to subscribers
        user = { ...userSubject.value, ...user };
        userSubject.next(user);
      }
      return user;
    });
}

// prefixed with underscore because 'delete' is a reserved word in javascript
const _delete = (id) => {
  return fetchWrapper.delete(`${URLs.apiUrl}/${id}`)
    .then(x => {
      // auto logout if the logged in user deleted their own record
      if (id === userSubject.value.id) {
        logout();
      }
      return x;
    });
}

let refreshTokenTimeout;

const startRefreshTokenTimer = () => {
  // parse json object from base64 encoded jwt token
  const jwtToken = JSON.parse(atob(userSubject.value.split('.')[1]));
  // set a timeout to refresh the token a minute before it expires
  const expires = new Date(jwtToken.exp * 1000);
  const timeout = expires.getTime() - Date.now() - (60 * 1000);
  refreshTokenTimeout = setTimeout(refreshToken, timeout);
}

const stopRefreshTokenTimer = () => {
  clearTimeout(refreshTokenTimeout);
}


const userId = () => {
  const token = localStorage.getItem("jwtToken");
  if (token) {
    const user = userService.readJwtToken(token);
    return +user.data.userId.slice(0, -1);
  }
  return;
}

export const userService = {
  login,
  logout,
  refreshToken,
  register,
  resendEmail,
  verifyEmail,
  forgotPassword,
  validateResetToken,
  resetPassword,
  changePassword,
  getAll,
  getById,
  create,
  update,
  readJwtToken,
  delete: _delete,
  userId,
  user: userSubject.asObservable(),
  get userValue() { return userSubject.value }
};