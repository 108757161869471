import React, { useEffect, useState } from "react";
import Overlay from "components/UI/Overlay";
import styles from "./Login.module.css";
import { Link } from "react-router-dom";
import { userService } from '../services/user.service';
import { Fragment } from "react";

const Activate = ({ match, location }) => {
  const [message, setMessage] = useState({ type: "", message: "" });
  const { params } = match;

  useEffect(() => {
    const fetchData = async () => {
      const response = await userService.verifyEmail(params.id);
      setMessage(response);
    };
    fetchData();
  }, [params]);

  return (
    <Fragment>
      <Overlay />
      <div
        className={`${styles["login-bg-wrapper"]} absolute top-0 w-full h-full bg-gray-900 bg-no-repeat bg-full`}
      ></div>
      <div className={`${styles["activate-container"]}`}>
        <div className={`${styles["p-40"]} bg-white rounded border-2 ${message.type === "SUCCESS" ? "border-green-500" : "border-red-500"}`}>
          {
            message.type === "SUCCESS" ? <p className="text-xl">{message.message}. Please click here to <Link className="underline font-bold" to="/login">
              Login
            </Link></p> : <p className="text-xl">{message.message}</p>
          }
        </div>
      </div>
    </Fragment>
  );
};
export default Activate;
