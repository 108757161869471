import React from "react";

const PriceFromat = (props) => {
  return (
    <React.Fragment>
      <span className="dollor">$</span>
      {props.value.toLocaleString("en-US", { maximumSignificantDigits: 7, valute: "USD" })}
    </React.Fragment>
  );
};

export default PriceFromat;
