import React, { useEffect, useState, useRef } from "react";
import ProjectLogo from "../UI/ProjectLogo";
import "./LogoDropdown.css";
import "../../assets/styles/scrollbar.css";

const LogoDropdown = ({ list, onLogo, className, selectedProjectItem, absolute = true }) => {
  const node = useRef();
  const [itemSelectedClass, setItemSelectedClass] = useState("");
  const [isListOpen, setIsListOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [projectListItems, setProjectListItems] = useState([]);
  const [projectListSearchItems, setProjectListSearchItems] = useState([]);

  useEffect(() => {
    setProjectListItems(list);
    setProjectListSearchItems(list);
    setSelectedItem(selectedProjectItem);
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [list, selectedProjectItem]);

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      setIsListOpen(true);
      return;
    }
    setIsListOpen(false);
  };

  const filterProject = (e) => {
    const searchTxt = e.target.value.trim();
    if (searchTxt) {
      const filteredItems = projectListItems.filter((item) =>
        item.name.toLowerCase().match(searchTxt)
      );
      setProjectListSearchItems(filteredItems.length ? filteredItems : []);
    } else {
      setProjectListSearchItems(projectListItems);
    }
  };

  const selectItem = (item) => {
    setSelectedItem(item);
    setItemSelectedClass("active");
    setIsListOpen(false);
    onLogo(item);
    setProjectListSearchItems(projectListItems);
  };

  return (
    <div className={`${className ? className : ""} cb-wrapper`} ref={node}>
      <button
        type="button"
        className={`form-control cb-header ${itemSelectedClass}`}
        onClick={handleClick}
      >
        <div className="cb-header-title">
          {selectedItem?.id ? (
            <ProjectLogo logo={selectedItem} path="projects" />
          ) : (
            "-Select Project-"
          )}
        </div>
        {isListOpen ? (
          <i className="fas fa-chevron-up text-gray-400"></i>
        ) : (
          <i className="fas fa-chevron-down text-gray-400"></i>
        )}
      </button>
      {isListOpen && (
        <ul className={`${absolute ? "absolute" : "relative"} cb-list custom-scrollbar`}>
          <input
            className="search-box p-2"
            type="text"
            placeholder="Search Project"
            onChange={filterProject}
          />
          {projectListSearchItems.length !== 0 &&
            projectListSearchItems.map((item, index) => {

              return item?.imageName ? (
                <li key={`${item.id}-${index}`}>
                  <button type="button" onClick={() => selectItem(item)}>
                    <ProjectLogo logo={item} path="projects" />
                  </button>
                </li>
              ) : ""
            })}
          {projectListSearchItems.length === 0 && <p>No results found</p>}
        </ul>
      )}
    </div>
  );
};

export default LogoDropdown;
