import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import DateFormat from "../UI/DateFormat";
import Status from "../UI/Status";
import ProjectLogo from "../UI/ProjectLogo";
import Modal from "../UI/Modal";
import Pagination from "../UI/Pagination";
import ConfirmModal from "components/UI/ConfirmModal";
import Loading from "components/UI/Loading";
import { Link } from "react-router-dom";

const EventList = ({ paginationConfig, isLoading, onDelete, onEdit, onFetchRecords, results }) => {
  const [selectedItem, setSelectedItem] = useState("");
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onClose = () => {
    document.body.classList.remove("overflow-hidden");
    setShowConfirmModal(false);
  };

  const onNavigationHandler = (limit, page) => {
    onFetchRecords(limit, page);
  };

  const onRemoveHandler = (item) => {
    setSelectedItem(item);
    setDeleteStatus(item.active);
    document.body.classList.add("overflow-hidden");
    window.scrollTo(0, 0);
    setShowConfirmModal(true);
  };

  const confirmHandler = (action) => {
    document.body.classList.remove("overflow-hidden");
    setShowConfirmModal(false);
    if (action) {
      onDelete(selectedItem);
    }
  };

  return (
    <div className="p-4">
      <Pagination
        className="mb-3"
        paginationConfig={paginationConfig}
        onNavigation={onNavigationHandler}
      />
      {showConfirmModal && (
        <Modal onClose={onClose} className="cb-small">
          <ConfirmModal
            description={`Are you sure want to ${+deleteStatus ? "de-activate" : "activate"
              }?`}
            onConfirmModal={confirmHandler}
          />
        </Modal>
      )}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th width="100px">Id</th>
            <th>Project</th>
            <th>Event Type</th>
            <th>Platform</th>
            <th width="100px">Start Date</th>
            <th width="100px">End Date</th>
            <th>Updated Date</th>
            <th>Updated By</th>
            <th>Active</th>
            <th width="115px">Action</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? <tr key="loading"><td colSpan={10}><Loading></Loading></td></tr> : results.map((item) => {
            return (
              <tr key={item.id}>
                <td>#P{item.id}</td>
                <td>
                  <ProjectLogo logo={item.project} path="projects" />
                </td>
                <td>{item.eventType}</td>
                <td>
                  <ProjectLogo logo={item.platform} path="projects" />
                </td>
                <td>{item.startDate === "0000-00-00 00:00:00" ? "-" : <DateFormat date={Date.parse(item.startDate)} />}</td>
                <td>{item.endDate === "0000-00-00 00:00:00" ? "-" : <DateFormat date={Date.parse(item.endDate)} />}</td>
                <td><DateFormat date={Date.parse(item.modifiedAt)} /></td>
                <td>{item.username}</td>
                <td><Status value={item.active} /></td>
                <td>
                  <div className="btn-container">
                    <Button
                      className="mr-2"
                      variant="secondary"
                      onClick={() => onRemoveHandler(item)}
                    >
                      <i className="fas fa-trash text-gray-400"></i>
                    </Button>
                    <Link
                      to={{
                        pathname: "/admin/event/form",
                        data: {
                          ...item, platformId: item.platform.id, projectId: item.project.id, startDate: item.startDate !== "0000-00-00 00:00:00" ? new Date(item.startDate) : "",
                          endDate: item.endDate !== "0000-00-00 00:00:00" ? new Date(item.endDate) : ""
                        }
                      }}
                    >
                      <Button className="float-right" variant="secondary">
                        <i className="fas fa-edit text-gray-400"></i>
                      </Button>
                    </Link>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Pagination
        paginationConfig={paginationConfig}
        onNavigation={onNavigationHandler}
      />
    </div>
  );
};


export default EventList;